/* LEYOUJIA_JS - V3.2.5 - Copyright © 2020 www.leyoujia.com - At: 2023-08-14 09:49:00 */
/**
 *
 * 3.0.0版本更新说明:
 *      1.更新埋点服务器
 *      2.取消sid埋点字段
 *      3.新增无埋点、界面唯一标示
 *      4.修复曝光埋点的load-BUG
 *
 * 3.0.1版本更新说明:
 *  1.修复onload bug
 *
 * 3.0.2版本更新说明:
 *  1.在客户端添加了会话ID的字段（仅限无埋点） -> sid
 *
 * 3.0.3版本更新说明:
 *  1.区分“页面浏览”和“非页面浏览”
 *
 * 3.0.4版本更新说明:
 *  1.修复"input"标签的evv转码的问题
 *
 * 3.0.5版本更新说明:
 *  1.添加_jjshome_t._get_click_obj()方法，获取“点击”事件的loc、evk、evv
 *
 * 3.0.6版本更新说明:
 *  1.添加pc、wap圈选业务代码
 * -2.调整windows.onload方法
 *
 * 3.0.7版本更新说明:
 *  1.调整第三方js为公司内部js
 *
 * 3.0.8版本更新说明:
 *  1.兼容埋点规则与pc、wap的圈选规则
 *
 * 3.0.9版本更新说明:
 *  1.修改控制台打印的logo
 *  2.修改招聘跳转的链接
 *  3.页面上引入多个js时，只显示一次警告信息
 *
 * 3.1.0版本更新说明:
 * -1.调整uuid,sid保存cookie时，设置顶级域名".leyoujia.com"
 *
 * 3.1.1版本更新说明:
 * -1.bi.leyoujia.com域名下的修改版本号
 *
  * 3.1.2版本更新说明:
 * -1.pc/wap圈选埋点修改，添加try catch
 *
  * 3.1.3版本更新说明:
 * -1.如果当前页面jquery不存在或者版本比较低时，动态注入高版本的jquery
 *
  * 3.1.4版本更新说明:
 * -1.增加通过动态加载js时获取wid跟mac的方法
 * -2.增加新系统自动获取wid跟mac的方法，避免调用麻烦
 *
  * 3.1.5版本更新说明:
 * -1.修复手机端不能获取stack的错误
 * 
 * 3.1.7版本更新说明：
 * -1.showDefineIndexDialog 圈选模式下点击元素屏蔽掉原有的点击事件
 * -2.修复截图不清晰bug（更新html2canvas版本，并允许加载跨域图片）
 * -3.修复因圈选框 lyj-circle-cover 导致定位不准确的bug（获取xpath过滤lyj-circle-cover）
 * -4.根据URL动态获取上传host
 * 
 * 3.1.8版本更新说明：
 * -1. 上传参数加密
 * -2. 引入rollup进行babel并混淆打包压缩
 * -3. 将crypto打包进js
 * 
 * 3.1.9版本更新说明：
 * -1. 放开setssui方法
 * -2. uid === 7e7ba742-6667-08f4-f6fc-7b4e31b9e5f8 则重新生成
 * 
 * 3.2.0版本更新说明：
 * -1. get 改成 post
 * 
 * 3.2.1版本更新说明：
 * -1. return_event 还是走nn.gif
 * 
 * 3.2.2版本更新说明：
 * -1. 增加从APP端获取wid方法
 * 
 * 3.2.3版本更新说明：
 * -1. evv 换行符替换
 * 
 * 3.2.4版本更新说明：
 * -1. window.onload 改为 window.addEventListener，不覆盖项目原有onload，而是在原有onload之后执行
 * 
 * 3.2.5版本更新说明:
 * -1. 原先创建失焦监听函数换成 visibilitychange
 * -2. 埋点初始化兼容window onload完成之后才引入埋点js的情况
 * -3. 修复圈选模式下，点击元素后，原有的点击事件被屏蔽的bug
 * 
 *  3.2.6版本更新说明:
 *  1、aes秘钥混淆
 * 
 *  3.2.7版本更新说明:
 *  元素新增log-gray
 * 
 * 大数据埋点JS
 * 1.手动触发型，适用于post表单提交事件埋点
 * _jjshome_t._set_event(evk, evv);
 *
 * 2.dom元素事件埋点 支持click、load，默认click
 * <a href="/v/zdhb/list" class="jjs_bd_log" log-evk="PISY0001" target="_blank"></a>
 * a标签被点击的时候传递事件埋点日志
 * <span class="jjs_bd_log" log-type="load" log-evk="PISL0001" log-evv="12345">12345</a>
 * 页面加载完毕后，传递事件埋点日志
 *
 * 3.post界面事件埋点加载
 * _jjshome_t._init_event_obj(document.getElementById("main-content"));
 *
 * 4.曝光埋点
 * <div class="jjs_bd_log" log-type="bg" log-evk="BGSY0001"></div>
 * 浏览器在滚动的过程中，当标记的标签完整出现在浏览器屏幕上时，认定该元素被曝光
 *
 * 5.新增兼容无埋点
 * 界面全元素添加click监听，获取xpath路径，来定位点击对象，并全部记录
 *
 * 6.添加界面唯一标示:&page-id=${page-id}
 * 获取方式:
 *  1).SELECT UUID();
 *  2).System.out.println(UUID.randomUUID().toString());
 *  3).https://www.uuidgenerator.net/
 *
 * */
import CryptoJS from "crypto-js";
try {
  if (_jjshome_t === undefined) {

    window.set_event_user_info = function (info) {
      try {
        var info = JSON.parse(info || '{}');
        const wid = info.workerId ? info.workerId : (info.empNumber ? info.empNumber : info.id)
        _jjshome_t._set_event_track_wid(wid)
        // 回调完成，发送一个页面埋点
        _jjshome_t._send();
      } catch (error) {
        console.warn('埋点JS从APP设置wid失败');
      }
    }

    if (isMobile()) {
      mdloadInfos();
    }

    let cryptoKey = 'T0RjeVlUVXhOR00xTjJNMg=='; // 秘钥
    cryptoKey = window.atob(cryptoKey); // 秘钥
    /**
     * 加密,返回加密后的值
     * word 要加密的参数
     * 使用方法 AesEncrypt(JSON.stringify(obj))
     */
    function AesEncrypt(word) {
      var code = CryptoJS.enc.Utf8.parse(cryptoKey);
      var srcs = CryptoJS.enc.Utf8.parse(word);
      var encrypted = CryptoJS.AES.encrypt(srcs, code, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
      return encrypted.toString();
    }

    // 获取加密和编码后的参数
    function getEncryptParams(params) {
      try {
        return encodeURIComponent(AesEncrypt(params));
      } catch (error) {
        console.warn('埋点JS参数加密失败');
      }
    }

    /**
     * 调用APP端函数，获取用户信息
     */
    function mdloadInfos() {
      var ua = navigator.userAgent.toLowerCase();
      try {
        if (/iphone|ipad|ipod/.test(ua)) {
          window.webkit.messageHandlers.loadInfos.postMessage({
            'callback': 'set_event_user_info'
          });
        } else if (/android/.test(ua)) {
          window.injs.loadInfos('set_event_user_info');
        }
      } catch (e) {
        // 这里加了定时器是为了兼容 ios 旧webview内核函数还没初始化完成的情况
        setTimeout(function () {
          if (typeof loadInfos === 'function') {
            loadInfos(JSON.stringify({
              'callback': 'set_event_user_info'
            }))
          }
        }, 800)
      }
    }

    /**
     * 判断是否移动端，ios貌似不支持 || 逻辑
     * @returns 
     */
    function isMobile() {
      var ua = navigator.userAgent.toLowerCase();
      if (/iphone|ipad|ipod/.test(ua)) {
        return true
      } else if (/android/.test(ua)) {
        return true
      }
      return false
    }

    // (async function () {
    //   await sleep(100);
    //   console.log('sleep')
    // })();

    if (!Array.from) {
      Array.from = function (el) {
        return Array.apply(this, el);
      }
    }

    var _jjshome_t = (function () {
      // var env = location.hostname.indexOf('.leyoujia.com') != -1 && location.hostname.indexOf('test') == -1 ? 'production' : 'development',
      // var hos_n = ("https:" == document.location.protocol ? "https" : "http") + ("://logtest.leyoujia.com/") + "nn.gif",
      // hos_v = ("https:" == document.location.protocol ? "https" : "http") + ("://logtest.leyoujia.com/") + "vn.gif",
      var hos_n = ("https:" == document.location.protocol ? "https" : "http") + "://data.leyoujia.com/nnp.gif",
        hos_v = ("https:" == document.location.protocol ? "https" : "http") + "://data.leyoujia.com/vnp.gif",
        hos_nold = ("https:" == document.location.protocol ? "https" : "http") + "://data.leyoujia.com/nn.gif",
      // var hos_n = ("https:" == document.location.protocol ? "https" : "http") + "://logtest.leyoujia.com/nnp.gif",
      //   hos_v = ("https:" == document.location.protocol ? "https" : "http") + "://logtest.leyoujia.com/vnp.gif",
      //   hos_nold = ("https:" == document.location.protocol ? "https" : "http") + "://logtest.leyoujia.com/nn.gif",
        loc = encodeURIComponent(window.location),
        ref = encodeURIComponent(document.referrer),
        click_obj = {},
        jjs = "jjshome_uuid",
        jjs_sid = "jjshome_sid",
        cla = "jjs_bd_log",
        levk = "log-evk",
        levv = "log-evv",
        ltype = "log-type",
        lgray = "log-gray",
        uid = getCookie(jjs),
        sid = getCookie(jjs_sid),
        pid = getPid(),
        tim = (new Date()).valueOf(),
        nua = navigator.userAgent,
        scw = screen.width,
        sch = screen.height,
        ver = "JS-V3.2.1",
        wid = getParameters("worker-id"),
        fid = getParameters("user-id"),
        utm = getQueryString("utm_term"),
        mac = getParameters("mac"),
        pid = getParameters("page-id"),
        appwid = '', // app端获取的wid
        //操作语言
        osl = navigator.language,
        //时区
        //tz = new Date().getTimezoneOffset();
        tz = getDateTz(),
        //设备方向
        ded = getOrient();
      try {
        // 7e7ba742-6667-08f4-f6fc-7b4e31b9e5f8 该ID在不同系统和用户中出现，怀疑是以前的bug，所遇遇到该id则重新生成
        if (uid == null || uid == '7e7ba742-6667-08f4-f6fc-7b4e31b9e5f8') {
          uid = getUuid();
          setCookie(jjs, uid);
        };
        if (sid == null) {
          sid = getUuid();
          setCookie(jjs_sid, sid, 60 * 30 * 1e3);
        };
      } catch (e) { };

      /**
       * 获取动态加载js时的参数
       */
      if (!wid || !mac) {
        // 获取链接地址上带的参数
        try {
          arguments.callee.caller.name
        } catch (e) {
          try {
            var stack = getStackTrace() || "";
            if (stack) {
              var t = stack.toString();
              var urlParams = t.substring(t.indexOf("?") + 1).split(":")[0];
              if (!wid) {
                wid = getQueryVariable("worker-id", urlParams);
              }
              if (!mac) {
                mac = getQueryVariable("mac", urlParams);
              }
            }
          } catch (e) {

          }
        }
      }

      /**
       * 获取新系统默认的参数
       */
      if (!wid) {
        wid = getCookie('login-workerid');
      }
      if (!mac) {
        mac = getCookie('login-mac');
      }

      var par = "loc=" + loc + "&ref=" + ref + "&uid=" + uid + "&pid=" + pid + "&nua=" + nua + "&scs=" + scw + "*" + sch + "&ver=" + ver + "&osl=" + osl + "&tz=" + tz + "&ded=" + ded;

      if (wid) par += "&wid=" + wid;
      if (fid != null) par += "&fid=" + fid;
      if (utm != null) par += "&utm=" + utm;
      if (mac != null) par += "&mac=" + mac;
      console.warn('执行了par赋值处理')
      function getPid() {
        return "xxxxxxxx.xxxxxxxx".replace(/[x]/g, function (c) {
          var r = Math.random() * 16 | 0, v = c == "x" ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      };
      function getUuid() {
        return "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx".replace(/[x]/g, function (c) {
          var r = Math.random() * 16 | 0, v = c == "x" ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      };
      function getStackTrace() {
        var obj = {};
        Error.captureStackTrace(obj, getStackTrace);
        return obj.stack;
      };
      function setCookie(name, value, expires) {
        var exp = new Date();
        if (expires) {
          exp.setTime(exp.getTime() + expires);
        } else {
          exp.setTime(exp.getTime() + 365 * 10 * 24 * 60 * 60 * 1e3);
        }
        document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString() + ";path=/;domain=.leyoujia.com";
      };
      function resetExpire(name) {
        var value = getCookie(name);
        if (!value) {
          value = getUuid();
        }
        sid = value;
        setCookie(name, value, 60 * 30 * 1e3);
      }
      function getCookie(name) {
        var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
        if (arr = document.cookie.match(reg)) {
          return decodeURIComponent(arr[2]);
        } else {
          return null;
        };
      };
      function getOrient() {
        if (window.orientation == 180 || window.orientation == 0) {
          return 1;
        };
        if (window.orientation == 90 || window.orientation == -90) {
          return 0;
        };
        return "";
      }
      function getDateTz() {
        var d = new Date();
        var localTime = d.getTime();
        var localOffset = d.getTimezoneOffset() * 60000;
        var utc = localTime + localOffset;
        var offset = 5.5;
        var calctime = utc + (3600000 * offset);
        var nd = new Date(calctime);
        return nd;
      };
      function _set_event(k, v,r) {
        if (!v) v = ''
        if (typeof v !== 'string') v += ''
        v = v.replace(/\r\n/g, "").replace(/\n/g, '').replace(/\r/g, '')
        var t = new Date().getTime();
        const params = getEncryptParams(par + '&evk=' + k + '&evv=' + v + '&t=' + t + '&sid=' + sid+ '&gray=' + r)
        ajax({ url: hos_n, data: params })
      };
      function _set_event_n(k, v,r) {
        if (k == '/html/body' || k == '') {
          return;
        };
        v = (v || '').replace(/\r\n/g, "").replace(/\n/g, '').replace(/\r/g, '')
        var t = new Date().getTime();
        resetExpire(jjs_sid);
        const params = getEncryptParams(par + '&evk=' + k + '&evv=' + v + '&t=' + t + '&sid=' + sid+ '&gray=' + r)

        ajax({ url: hos_n, data: params })
      };
      function _return_event(k, v) {
        var t = new Date().getTime();
        const params = getEncryptParams(par + '&evk=' + k + '&evv=' + v + '&t=' + t)
        return hos_nold + '?' + params;
      };
      function _send() {
        const params = getEncryptParams(par + '&sid=' + sid)
        ajax({ url: hos_v, data: params })
      };
      function _send_n(img_src) {
        const img = new Image;
        resetExpire(jjs_sid);
        img.src = img_src;
      };
      function getQueryString(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substring(1).match(reg);
        if (r != null) {
          return decodeURIComponent(r[2]);
        };
        return null;
      };
      function getParameters(name) {
        var script = document.getElementsByTagName("script"), l = script.length;
        var me = ''
        for (var i = 0; i < l; i++) {
          me = !!document.querySelector ? script[i].src : script[i].getAttribute("src", 4);
          if (me.substring(me.lastIndexOf("/")).indexOf("menu_hover") !== -1) {
            return null;
          };
        };
        var urlParameters = me.split("?")[1];
        if (urlParameters) {
          var parame = urlParameters.split("&"),
            i = 0,
            l = parame.length,
            arr;
          for (var i = 0; i < l; i++) {
            arr = parame[i].split("=");
            if (name === arr[0]) {
              return arr[1];
            };
          };
        };
        return null;
      };
      function getQueryVariable(variable, url) {
        var vars = url.split("&");
        for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split("=");
          if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
      };
      function addEvent(target, type, func) {
        if (typeof target === 'string') return;
        if (target.addEventListener) {
          target.addEventListener(type, func, true);
        } else if (target.attachEvent) {
          target.attachEvent("on" + type, func);
        } else { target["on" + type] = func; };
      };
      function removeEvent(target, type, func) {
        if (typeof target === 'string') return;
        if (target.removeEventListener) {
          target.removeEventListener(type, func, false);
        } else if (target.detachEvent) {
          target.detachEvent("on" + type, func);
        } else { target["on" + type] = null; };
      };
      function addEventHandler(obj, evk, evv, type,gray) {
        if (obj.attachEvent) {
          obj.attachEvent("on" + type, function () { _set_event(evk, evv,gray); });
        } else if (obj.addEventListener) {
          obj.addEventListener(type, function () { _set_event(evk, evv,gray); }, false);
        } else {
          obj["on" + type] = function () { _set_event(evk, evv,gray); };
        };
      };
      var addEventN = function (evt) {
        var e = evt || window.event;
        var el = e.srcElement ? e.srcElement : e.target;
        // 兼容圈选的规则
        el = convertCircleObj(el);
        if (el.innerHTML.length <= 300) {
          var ele = getElementXPath(el);
          click_obj = ele;
          _set_event_n(ele.evk, ele.evv, ele.gray);
        };
      };
      function convertCircleObj(target) {
        var inlineNoMarkEl = ['SPAN', 'I'];
        var isLeafParent = true;
        var children = target.children;
        if (children.length === 0) {
          // 1. target 是叶子节点。 如果target是span、i，并且父元素是a的情况下， 圈target的父元素; 否则圈target
          if (target.parentNode.nodeName === 'A' && (inlineNoMarkEl.indexOf(target.nodeName) !== -1)) {
            return target.parentNode;
          } else {
            return target;
          }
        } else {
          // 2. target 非叶子节点 并且 target是叶子节点的父节点。 如果 target 是span、i，并且父元素是a,圈父元素a;
          for (var i = 0; i < children.length; i++) {
            if (children[i].children.length) {
              isLeafParent = false;
              break;
            }
          }
          if (isLeafParent) {
            if ((inlineNoMarkEl.indexOf(target.nodeName) !== -1) && target.parentNode.nodeName === 'A') {
              return target.parentNode;
            }
            return target;
          } else {
            return target;
          }
        }

      };
      function addClickHandler(obj) {
        addEvent(obj, "click", addEventN, true);
      };
      function removeClickHandler(obj) {
        removeEvent(obj, "click", addEventN, true);
      };
      function addModifiedHandler(obj) {
        // obj.addEventListener("DOMSubtreeModified", function(evt){
        removeClickHandler(document);
        addClickHandler(document);
        _jjshome_t._init_event();
        // }, true);
      };
      if (!document.getElementsByClassName) {
        document.getElementsByClassName = function (className, element) {
          var children = (element || document).getElementsByTagName('*');
          var elements = new Array();
          for (var i = 0; i < children.length; i++) {
            var child = children[i];
            var classNames = child.className.split(' ');
            for (var j = 0; j < classNames.length; j++) {
              if (classNames[j] == className) {
                elements.push(child);
                break;
              };
            };
          };
          return elements;
        };
      };
      function isElementInViewport(el) {
        var rect = el.getBoundingClientRect();
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth) &&
          !el.hasEvent
        );
      };
      function givenElementInViewport(el) {
        return function () {
          if (isElementInViewport(el)) {
            _set_event(el.evk, el.evv,el.gray);
            el.hasEvent = true;
          };
        };
      };
      function addBgEvent(el) {
        el.hasEvent = false;
        if (window.addEventListener) {
          addEventListener('load', givenElementInViewport(el), false);
          addEventListener('scroll', givenElementInViewport(el), false);
        } else if (window.attachEvent) {
          attachEvent('load', givenElementInViewport(el));
          attachEvent('scroll', givenElementInViewport(el));
        };
      };
      function getElementXPath(element) {
        var result = {};
        if (!element) return null;
        if (element.tagName.toLowerCase() == 'img') {
          if (element.nextSibling != null && element.nextSibling.nodeName == '#text' && element.nextSibling.data.trim() != '') {
            result.evv = element.nextSibling.data.trim();
          } else {
            result.evv = element.src;
          };
        } else {
          if (element.innerText) {
            if (element.innerText.length >= 20) {
              result.evv = element.innerText.substring(0, 19);
            } else {
              result.evv = element.innerText;
            };
          } else {
            if (element.title) {
              if (element.title.length >= 20) {
                result.evv = element.title.substring(0, 19);
              } else {
                result.evv = element.title;
              };
            } else if (element.value) {
              result.evv = decodeURIComponent(element.value);
            } else {
              result.evv = '';
            };
          };
        };
        if (element.id) {
          result.evk = '//*[@id="' + element.id + '"]';
        } else if (element.tagName === 'BODY') {
          result.evk = '/html/body';
        } else {
          const sameTagSiblings = Array.from(element.parentNode.childNodes).filter(function (e) {
            return (e.nodeName === element.nodeName && (!e.className || (typeof e.className === 'string' && e.className.indexOf('lyj-circle-cover') == -1)))
          });
          const idx = sameTagSiblings.indexOf(element);
          result.evk = getElementXPath(element.parentNode).evk + '/' + element.tagName.toLowerCase() + (sameTagSiblings.length > 1 ? '[' + (idx + 1) + ']' : '');
        };
        result.loc = window.location.toString();
        // 替换所有回车和换行符
        result.evv = result.evv.replace(/\r\n/g, "").replace(/\n/g, '').replace(/\r/g, '')
        //新增gray
        result.gray = element.getAttribute('log-gray') || '';
        return result;
      };
      function _init_document_click(obj) {
        addModifiedHandler(obj);
      };
      function _init_event_obj(obj) {
        if (obj) {
          removeClickHandler(cla);//移除原有绑定事件
          addClickHandler(cla);//新增新的绑定事件
          var evobjs = obj.getElementsByClassName(cla);
          if (evobjs != null && evobjs.length > 0) {
            for (var i = 0; i < evobjs.length; i++) {
              var obj = evobjs[i];
              try {
                var evk = obj.getAttribute(levk);
                var evv = obj.getAttribute(levv);
                var type = obj.getAttribute(ltype);
                var gray = obj.getAttribute(lgray);
                if (!evv) {
                  evv = "";
                };
                evv = evv.replace(/\r\n/g, "").replace(/\n/g, '').replace(/\r/g, '')
                if (!type) {
                  type = "click";
                };
                if (type == "load") {
                  _set_event(evk, evv,gray);
                } else if (type == "bg") {
                  obj.evk = evk;
                  obj.evv = evv;
                  obj.gray = gray;
                  addBgEvent(obj);
                } else {
                  addEventHandler(obj, evk, evv, type,gray);
                };
              } catch (e) { };
            };
          };
        };
      };
      function _init_event() {
        _init_event_obj(document);
      };
      function _init_event_n() {
        console.log('collect js init')
        _init_document_click(document);
      };
      function _get_click_obj() {
        return click_obj;
      };
      function ajax(options) {
        options = Object.assign({
          type: 'post',
          dataType: 'json',
          timeout: 3000,
          contentType: "application/json"
        }, options);
        options.type = (options.type || "GET").toUpperCase();/// 请求格式GET、POST，默认为GET
        options.dataType = options.dataType || "json";    //响应数据格式，默认json

        // var params = formatParams(options.data);//options.data请求的数据
        var params = options.data

        var xhr;

        //考虑兼容性
        if (window.XMLHttpRequest) {
          xhr = new XMLHttpRequest();
        } else if (window.ActiveObject) {//兼容IE6以下版本
          xhr = new ActiveXobject('Microsoft.XMLHTTP');
        }

        //启动并发送一个请求
        if (options.type == "GET") {
          xhr.open("GET", options.url + "?" + params, true);
          xhr.send(null);
        } else if (options.type == "POST") {
          xhr.open("post", options.url, true);

          //设置表单提交时的内容类型
          //Content-type数据请求的格式
          xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
          xhr.send(params);
        }

        // 设置有效时间
        setTimeout(function () {
          if (xhr.readySate != 4) {
            xhr.abort();
          }
        }, options.timeout);

        //    接收
        //     options.success成功之后的回调函数  options.error失败后的回调函数
        //xhr.responseText,xhr.responseXML  获得字符串形式的响应数据或者XML形式的响应数据
        xhr.onreadystatechange = function () {
          if (xhr.readyState == 4) {
            var status = xhr.status;
            if (status >= 200 && status < 300 || status == 304) {
              options.success && options.success(xhr.responseText, xhr.responseXML);
            } else {
              if (options.error) {
                options.error(status);
              }
            }
          }
        }
      }
      // 提供给外部设置workerId，轻应用和H5使用
      function _set_event_track_wid(workerId) {
        console.log('_set_event_track_wid:', workerId)
        console.log('_set_event_track_wid 相关判断参数:', wid, par, workerId && !wid && par.indexOf('wid') === -1)
        if (workerId && !wid && par.indexOf('wid') === -1) {
          console.log('_set_event_track_wid 赋值:', workerId)
          wid = workerId
          if (wid != null) par += "&wid=" + wid
        }
      }
      return {
        _init_event_obj: function (obj) { _init_event_obj(obj); },
        _init_event: _init_event,
        _init_event_n: _init_event_n,
        _send: _send,
        time: tim,
        pid: pid,
        wid: wid,
        appwid: appwid,
        _get_click_obj: _get_click_obj,
        _set_event: _set_event,
        _return_event: _return_event,
        _set_event_track_wid: _set_event_track_wid
      };
    })();

    window._jjshome_t = _jjshome_t;

    // var oldonload = window.onload;
    // if (typeof window.onload != 'function') {
    //   window.οnlοad = _jjshome_t._init_event_n();
    // } else {
    //   window.οnlοad = function () {
    //     console.log('collect js onload')
    //     oldonload();
    //     _jjshome_t._init_event_n();
    //   }
    // }

    if (document && document.readyState === 'complete') {
      _jjshome_t._init_event_n();
    } else {
      window.addEventListener('load', function () {
        _jjshome_t._init_event_n();
      })
    }

    // 非移动端直接发送一个页面埋点，如果是移动端等回调完成再去发送
    if (!isMobile()) {
      _jjshome_t._send();
    }

    // _jjshome_t._init_event_n();
    // window.onblur = function () {
    //   _jjshome_t.time = (new Date()).valueOf() - _jjshome_t.time;
    //   _jjshome_t._set_event("SJ00002", _jjshome_t.time);
    // };
    document.addEventListener('visibilitychange', function () {
      if (document.hidden) {
        _jjshome_t.time = (new Date()).valueOf() - _jjshome_t.time;
        _jjshome_t._set_event("SJ00002", _jjshome_t.time);
      }
    });

    window.onfocus = function () {
      _jjshome_t.time = (new Date()).valueOf();
    };
    window.onunload = function () {
      _jjshome_t.time = (new Date()).valueOf() - _jjshome_t.time;
      _jjshome_t._set_event("SJ00003", _jjshome_t.time);
    };
    window.setssui = function (a, b, c) {
      _jjshome_t._set_event(a, b);
    };
    try {
      if (document.domain == "i.leyoujia.com" || document.domain == "coa.leyoujia.com") {
        // 判断是否存在的iframe且父iframe已经引用过埋点js
        if (!(self != top && window.parent._jjshome_t)) {
          var c_img_wj = "font-size:32px;color:red;-webkit-text-fill-color:red;-webkit-text-stroke: 1px black;margin-left:10px;padding:5px 50px;display: inline-block;width: 66px;height: 66px;line-height:66px;",
            warn = "\u58f0\u660e\uff1a",
            wid = "";
          if (_jjshome_t.wid != null && _jjshome_t.wid != "") {
            wid = _jjshome_t.wid;
            warn += "\u5de5\u53f7\uff08" + _jjshome_t.wid + "\uff09";
          };
          var img = _jjshome_t._return_event("SJ00110", wid)
          c_img_wj += "background-image: url(https://imgcloud.leyoujia.com/image/jpg/beianbgs.png),url(\"" + img + "\");background-repeat:no-repeat;background-size: 44px;";
          warn += "\u8bf7\u7acb\u5373\u505c\u6b62\u64cd\u4f5c\uff0c\u6211\u4eec\u5c06\u4f1a\u8bb0\u5f55\u4f60\u7684\u6240\u6709\u884c\u4e3a\u3002\u000d\u000a\u0020\u0020\u0020\u0020\u0020\u0020\u82e5\u5f15\u4e0d\u826f\u540e\u679c\uff0c\u6211\u4eec\u5c06\u5bf9\u4f60\u8fdb\u884c\u884c\u653f\u5904\u7f5a\uff0c\u4e25\u91cd\u8005\u63d0\u4ea4\u53f8\u6cd5\u673a\u5173\u5904\u7406\u3002";
          console.log("%c \u5b89\u5168\u8b66\u544a\uff01", c_img_wj);
          console.log("%c " + warn, "font-size: 20px;color:#333");
        }
      } else {
        var c_img = "margin-left:95px;padding:40px 100px;display: inline-block;width: 195px;height: 100px;background: url(https://alicdn.leyoujia.com/images/common/logo2@2x.png) 0 0 no-repeat;",
          c_hr = "font-size: 18px;color: #e60012;letter-spacing: 0;line-height: 18px;";
        var banner_str = "            ___   ____         ____   ___\n" +
          "           |   |  \\   \\       /   /  |   |\n" +
          "           |   |   \\   \\     /   /   |   |\n" +
          "           |   |    \\   \\   /   /    |   |\n" +
          "           |   |     \\   \\_/   /     |   |\n" +
          "           |   |      \\       /      |   |\n" +
          "           |   |       \\     /       |   |\n" +
          "           |   |        |   |        |   |\n" +
          "           |   |        |   |___     |   |\n" +
          "           |   |________|   |   |____|   |\n" +
          "           |            |   |\\           / \n" +
          "           |____________|___| \\_________/  \n" +
          "                                          ";
        console.log("%c ", c_img);
        console.log("%c" + banner_str + "\n               数据推动进步,智能创造未来                 \n", null);
        console.log("%c  \u6c6a\u007e\u0020\u52a0\u5165\u6211\u4eec\u5427\u0020\u0068\u0074\u0074\u0070\u003a\u002f\u002f\u0073\u002e\u006c\u0065\u0079\u006f\u0075\u006a\u0069\u0061\u002e\u0063\u006f\u006d\u002f\u0032\u0061\u0032\u0061\u0061\u0069", c_hr);
      };
    } catch (e) {
      _jjshome_t._set_event("SJ00500", "");
    };
  } else {
    console.error("\u91cd\u590d\u5f15\u7528\u4e50\u6709\u5bb6\u57cb\u70b9\u004a\u0053\uff01");
  };

  if (_jjshome_t != undefined) {
    // 不标记的元素
    var noMarkEl = ['SCRIPT', 'LINK', 'STYLE', 'META', 'TITLE', 'HEAD', 'HTML'];
    // 作为叶子节点且父节点为A时不标记的元素
    var inlineNoMarkEl = ['SPAN', 'I'];
    // 保存事件的引用
    var eventPoint = {
      click: {},
      focus: {}
    };

    var PC = 1;
    var WAP = 2;

    // 页面状态   0:浏览。1：圈选。2：热图
    var _pageStatus = 0;

    // 圈选状态时禁止的事件
    var banEvents = ['click', 'focus'];

    var xpathObjList = [];

    postMetaMsg();

    window.addEventListener("message", receiveMessage, false);

    $(document.body).addClass('lyj-qx-highlight');
    injectStyle();
    // injectScript();

    function injectStyle() {
      var cssStr = '.lyj-circle-hovered{outline:2px solid #ed495f!important;outline-offset:-2px!important;-webkit-box-shadow:0 2px 8px rgba(0,0,0,0.8)!important;-moz-box-shadow:0 2px 8px rgba(0,0,0,0.8)!important;box-shadow:0 2px 8px rgba(0,0,0,0.8)!important}.lyj-circle-cover{position:absolute;border:2px solid #01b7fe;background:rgba(1,183,254,0.15);z-index:100000;box-sizing:border-box}.lyj-qx-highlight .lyj-circle-tagged-cover{position:absolute;border:2px solid #ef475c;background-color:rgba(239,71,92,0.17);z-index:100000} .wap-scroll{overflow-x: hidden; width: 375px;} ';
      $('<style>' + cssStr + '</style>').appendTo($('head'));
    }

    function isLowJqVersion() {
      if ('undefined' === typeof jQuery) return true;
      var v = jQuery.fn.jquery;
      var vs = v.split('.');
      if (Number(vs[0]) > 1) return false;
      return Number(vs[1]) < 10
    }

    function injectScript() {
      if ("undefined" === typeof html2canvas) {
        doInjectScriptToBody('https://front.leyoujia.com/js/analysis/html2canvas-1.3.2.min.js');
      }
      if (isLowJqVersion()) {
        doInjectScriptToBody('https://front.leyoujia.com/js/jquery.js');
      }
    }

    function doInjectScriptToBody(src) {
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.src = src;
      $("body").append(s);
    }

    function postMetaMsg() {
      var url = window.location.href;
      var realUrl = url;
      var index1 = url.indexOf('&__refresh__=');
      var index2 = url.indexOf('?__refresh__=');

      if (index1 !== -1) {
        realUrl = urlTool(url, index1);
      } else if (index2 !== -1) {
        realUrl = urlTool(url, index2);
      }
      window.parent.postMessage({
        pageTitle: document.title,
        hasBigDataJs: window['_jjshome_t'] !== undefined,
        pageUrl: decodeURI(realUrl)
      }, '*');

      function urlTool(url, start) {
        var realUrl = url.slice(0, start);
        var j = url.slice(start + 1).indexOf('&');
        if (j !== -1) {
          if (realUrl && realUrl.indexOf('=') === -1) {
            realUrl += '?' + url.slice(start + 1).slice(j + 1);
          } else {
            realUrl += url.slice(start + 1).slice(j);
          }
        }
        return realUrl;
      }
    }


    /**
     * receiveMessage - 接收到父窗口信息后调用的处理器
     *
     * @param  {type} event description
     * @return {type}       description
     */
    function receiveMessage(event) {
      // var origin = event.origin || event.originalEvent.origin;
      // if (origin !== "http://127.0.0.1:51508") return;
      var data = event.data;
      var pageStatus = data.pageStatus;
      var cancelElemDefineDialog = data.cancelElemDefineDialog;
      var operHistory = data.history;
      if (pageStatus !== undefined) {
        _pageStatus = pageStatus;
        onPageStatusChange(pageStatus);
      }
      if (data.pageType) {
        $('html').toggleClass('wap-scroll', data.pageType === WAP);
        if (data.pageType === WAP) { // wap
          $('html head').append($('<style id="scrollStyle">::-webkit-scrollbar {display: none; width: 0; }</style>'));
        } else {
          $('html head').find('#scrollStyle').remove();
        }
      }
      if (cancelElemDefineDialog) {
        $('.lyj-circle-clicked').removeClass('lyj-circle-clicked');
        $('.lyj-circle-cover').hide();

        if (_pageStatus === 1) {
          $('.lyj-circle-tagged-cover').remove();
          showDefinedElemIndex(xpathObjList);
        }
      }
      operHistory && history.go(operHistory);
      if (data.xpathObjList) {
        $('.lyj-circle-tagged-cover').remove();
        xpathObjList = data.xpathObjList;
        showDefinedElemIndex(xpathObjList);
      }
      if (data.highlightDefinedIndex !== undefined) {
        $(document.body).toggleClass('lyj-qx-highlight', data.highlightDefinedIndex);
      }
      if (data.screenshotForPage) {
        screenshotForPage();
      }
      if (data.screenshotForElem) {
        screenshotForElem();
      }
    }


    function screenshotForElem() {
      screenshot(function (canvas) {
        var dataUrl = '';
        try {
          dataUrl = canvas.toDataURL();
        } catch (e) {
          dataUrl = 'error:' + new Date().getTime();
        } finally {
          window.parent.postMessage({
            elemScreenshot: {
              dataUrl: dataUrl
            }
          }, '*');
        }
      });
    }

    /**
     * screenshotForPage - 定义页面指标时截图
     *
     * @return {type}  description
     */
    function screenshotForPage() {
      screenshot(function (canvas) {
        var dataUrl = '';
        try {
          dataUrl = canvas.toDataURL();
        } catch (e) {
          dataUrl = 'error:' + new Date().getTime();
        } finally {
          window.parent.postMessage({
            pageScreenshot: {
              dataUrl: dataUrl
            }
          }, '*');
        }
      });
    }

    function screenshot(callback) {
      html2canvas(document.body, {
        useCORS: false,
        // allowTaint: true,
        // taintTest: false,
        logging: false,
        // width: document.body.clientWidth,
        // height: document.body.clientHeight,
        // x: 0,
        // y: $(window).scrollTop(),
        ignoreElements: function (elem) {
          return $(elem).hasClass('lyj-circle-tagged-cover');
        }
      }).then(function (canvas) {
        callback(canvas);
      });
    }

    /**
     * showDefinedElemIndex - 高亮已定义的元素
     *
     * @param  {type} data description
     * @return {type}      description
     */
    function showDefinedElemIndex(data) {
      $.each(data, function (i, item) {
        var elem = getElemByXPath(item.evk);
        var realElem = getRealIndexElem(elem);
        $(realElem).attr('data-index-id', item.indexId);
        addIndexElemCover(realElem, item);
      });
    }


    /**
     * addIndexElemCover - 为元素添加高亮遮罩
     *
     * @param  {type} elem description
     * @return {type}      description
     */
    function addIndexElemCover(elem, data) {
      var $elem = $(elem);
      if ($elem.css('position') === 'static') {
        $elem.css('position', 'relative');
      }
      $elem.attr({
        'data-qx-evv': data.evv,
        'data-qx-evk': data.evk,
        'data-qx-loc': data.loc
      });
      createTaggedCover().css({
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 0
      }).appendTo($elem).show();
    }

    /**
     * getRealIndexElem - 通过xpath表示的元素获取到真正圈选的元素
     *
     * @param  {type} elem description
     * @return {type}      description
     */
    function getRealIndexElem(elem) {
      if (inlineNoMarkEl.indexOf(elem.nodeName) !== -1
        && (elem.parentNode.nodeName === 'A')) {
        return elem.parentNode;
      }
      return elem;
    }

    function onPageStatusChange(pageStatus) {
      injectScript();
      if (pageStatus === 0) {
        $(document.body).off({
          mouseover: mouseoverHandler,
          mouseout: mouseoutHandler,
        });
        removeQXClass();
        hideQXDialog();
        $('a').off('click', banClickEvt);
        document.body.removeEventListener('click', showDefineIndexDialog, true);
      } else if (pageStatus === 1) {
        $(document.body).on({
          mouseover: mouseoverHandler,
          mouseout: mouseoutHandler,
        });
        $('a').on('click', banClickEvt);
        document.body.addEventListener('click', showDefineIndexDialog, true);
      } else if (pageStatus === 3) {
        $(document.body).off({
          mouseover: mouseoverHandler,
          mouseout: mouseoutHandler,
        });
        removeQXClass();
        hideQXDialog();
        $('a').off('click', banClickEvt);
      }
    }

    function removeQXClass() {
      $('.lyj-circle-tagged-cover').remove();
      $('.lyj-circle-cover').remove();
    }

    function hideQXDialog() {
      window.parent.postMessage({
        hideDefineElemDialog: true
      }, '*');
    }
    // 1. target 是叶子节点。 如果target是span、i，并且父元素是a的情况下， 圈target的父元素; 否则圈target
    // 2. target 非叶子节点 并且 target是叶子节点的父节点。 如果 target 是span、i，并且父元素是a,圈父元素a;
    function mouseoverHandler(event) {
      var target = event.target;
      var isLeafParent = true;
      if (noMarkEl.indexOf(target.nodeName) !== -1) return;
      var children = target.children;
      if (children.length === 0) {
        if (target.parentNode.nodeName === 'A' && (inlineNoMarkEl.indexOf(target.nodeName) !== -1)) {
          markNode(target.parentNode);
        } else {
          markNode(target);
        }
      } else {
        for (var i = 0; i < children.length; i++) {
          if (children[i].children.length) {
            isLeafParent = false;
            break;
          }
        }
        if (isLeafParent) {
          if ((inlineNoMarkEl.indexOf(target.nodeName) !== -1) && target.parentNode.nodeName === 'A') {
            markNode(target.parentNode);
            return;
          }
          markNode(target);
          eachMarkNode(target);
        } else if (target.nodeName === 'A') {
          var isMarkTarget = true;
          for (var i = 0; i < children.length; i++) {
            if (inlineNoMarkEl.indexOf(children[i].nodeName) === -1) {
              isMarkTarget = false;
              break;
            }
          }
          if (isMarkTarget) {
            markNode(target);
          } else {
            eachMarkNode(target);
          }
        }
      }
    }

    function mouseoutHandler(event) {
      var target = event.target;
      if (noMarkEl.indexOf(target.nodeName) !== -1) return;
      noMarkNode(target);
      noMarkNode(target.parentNode);
      if (target.nodeName === 'A') {
        eachNoMarkNode(target);
      } else {
        var children = target.children;
        for (var i = 0; i < children.length; i++) {
          if (noMarkEl.indexOf(children[i].nodeName) !== -1) continue;
          noMarkNode(children[i]);
        }
      }
    }

    /**
     * markNode - 标记元素
     *
     * @param  {type} node
     */
    function markNode(node) {
      if ($(node).hasClass('lyj-circle-clicked') || $(node).hasClass('lyj-circle-cover')) return;
      node.classList.add('lyj-circle-hovered');
      closeMarkNodeEventHandler(node);
    }

    /**
     * noMarkNode - 取消标记元素
     *
     * @param  {type} node
     */
    function noMarkNode(node) {
      node.classList.remove('lyj-circle-hovered');
      openMarkNodeEventHandler(node);
    }

    /**
     * eachNoMarkNode - node的子节点取消标记
     *
     * @param  {type} node
     */
    function eachNoMarkNode(node) {
      for (var i = 0; i < node.children.length; i++) {
        if (noMarkEl.indexOf(node.children[i].nodeName) !== -1) continue;
        noMarkNode(node.children[i]);
        eachNoMarkNode(node.children[i]);
      }
    }

    /**
     * eachMarkNode - 标记node的子节点
     *
     * @param  {type} node
     */
    function eachMarkNode(node) {
      for (var i = 0; i < node.children.length; i++) {
        if (node.nodeName === 'A' && (inlineNoMarkEl.indexOf(node.children[i].nodeName) !== -1)) return;
        if (noMarkEl.indexOf(node.children[i].nodeName) !== -1) continue;
        markNode(node.children[i]);
        eachMarkNode(node.children[i]);
      }
    }


    /**
     * closeMarkNodeEventHandler - 禁用标记元素的交互事件
     *
     * @param  {type} node
     */
    function closeMarkNodeEventHandler(node) {
      banEvents.forEach(function (eventType, i) {
        if (node['on' + eventType]) {
          var eventId = guid();
          node.setAttribute('data-' + eventType, eventType);
          eventPoint[eventType][eventId] = node.onclick;
        }
        if (jQuery) {
          var events = $._data($(node)[0], 'events') ? $._data($(node)[0], 'events')[eventType] : null;
          events && $.each(events, function (i, item) {
            var eventId = guid();
            var oldEvts = node.getAttribute('data-jquery-' + eventType) ? node.getAttribute('data-jquery-' + eventType) + ',' : '';
            node.setAttribute('data-jquery-' + eventType, oldEvts + eventId);
            eventPoint[eventType][eventId] = item.handler;
            item.handler = banClickEvt;
          });
        }
        node['on' + eventType] = banClickEvt;
      });
    }


    /**
     * openMarkNodeEventHandler - 启用标记元素的交互事件
     *
     * @param  {type} node
     */
    function openMarkNodeEventHandler(node) {
      banEvents.forEach(function (eventType, i) {
        var eventId = node.getAttribute('data-' + eventType);
        node['on' + eventType] = eventId ? eventPoint[eventType][eventId] : null;
        if (jQuery) {
          var events = $._data($(node)[0], 'events') ? $._data($(node)[0], 'events')[eventType] : null;
          var eventHandlers = node.getAttribute('data-jquery-' + eventType);
          if (events && eventHandlers) {
            var eventArr = eventHandlers.split(',');
            $.each(events, function (i, item) {
              item.handler = eventPoint[eventType][eventArr[i]];
            });
            node.removeAttribute('data-jquery-' + eventType);
          }
        }
      })
    }

    function banClickEvt(e) {
      $(e.target).blur();
      e.stopPropagation();
      e.stopImmediatePropagation();
      return false;
    }

    function showDefineIndexDialog(event) {
      var target = event.target;
      var isClickCover = false;
      var show = false;
      if (target.classList.contains('lyj-circle-tagged-cover')) {
        show = true;
        isClickCover = true;
        target = target.parentElement;
      } else if (target.classList.contains('lyj-circle-hovered')) {
        show = true;
      } else if (target.parentElement.classList.contains('lyj-circle-hovered')) {
        show = true;
        target = target.parentElement;
      }
      if (!show) return;
      $('.lyj-circle-cover').remove();
      $('.lyj-circle-tagged-cover').remove();
      var $cover = createCover();
      moveCover(target, $cover);
      transformInfo(target, isClickCover);
    }

    function moveCover(target, cover) {
      var $target = $(target);
      if ($target.css('position') === 'static') {
        $target.css('position', 'relative');
      }
      cover.appendTo($target).css({
        'position': 'absolute',
        'top': 0,
        'left': 0,
        'width': $target.outerWidth() + 'px',
        'height': $target.outerHeight() + 'px',
        'margin': 0
      }).show();

      $('.lyj-circle-clicked').removeClass('lyj-circle-clicked');
      $target.removeClass('lyj-circle-hovered').addClass('lyj-circle-clicked')
        .find('.lyj-circle-hovered').removeClass('lyj-circle-hovered').addClass('.lyj-circle-clicked');
    }

    function transformInfo(target, isClickCover) {
      var boundingClientRect = target.getBoundingClientRect();
      var o = _jjshome_t._get_click_obj();
      o.isAElem = target.nodeName === 'A';
      var $target = $(target);
      if (isClickCover) {
        o = {
          evv: $target.attr('data-qx-evv'),
          evk: $target.attr('data-qx-evk'),
          loc: $target.attr('data-qx-loc')
        }
      }
      // 直接使用boundingClientRect传入存在兼容问题
      window.parent.postMessage({
        boundingClientRect: {
          bottom: boundingClientRect.bottom,
          height: boundingClientRect.height,
          left: boundingClientRect.left,
          right: boundingClientRect.right,
          top: boundingClientRect.top,
          width: boundingClientRect.width
        },
        tagInfo: o,
        indexId: target.getAttribute('data-index-id')
      }, '*');
    }


    /**
     * createCover - 创建高亮遮罩
     *
     * @return {type}  description
     */
    function createCover() {
      return $('<div class="lyj-circle-cover" style="display:none;">').click(function (e) {
        e.stopImmediatePropagation();
        e.stopPropagation();
        return false;
      });
    }

    function createTaggedCover() {
      return $('<div class="lyj-circle-tagged-cover" style="display:none;">').click(function (e) {
        e.stopImmediatePropagation();
        e.stopPropagation();
        return false;
      });
    }


    /**
     * guid - 生成UUID
     *
     * @return {type}  UUID
     */
    function guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
          v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }


    /**
     * getElemByXPath - 通过xpath获取元素
     *
     * @param  {type} xpath description
     * @return {type}       description
     */
    function getElemByXPath(xpath) {
      var result = document.evaluate(xpath, document, null, XPathResult.ANY_TYPE, null);
      return result.iterateNext();
    }

    //格式化请求参数
    function formatParams(data) {
      var arr = [];
      for (var name in data) {
        arr.push(encodeURIComponent(name) + "=" + encodeURIComponent(data[name].replace(/(\r\n\t|\n|\r\t)/gm, "")));
      }
      return arr.join("&");
    }
  }
} catch (error) {
  console.log('collect error:', error)
}
